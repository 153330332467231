import React from "react"
import styled from "styled-components"
import { H2 } from "./H2"

const Alert = styled.div`
  background: ${props => props.theme.colors.lightYellow};
  padding: 2rem;
  border: 0.25px solid ${props => props.theme.colors.black};
`

export const UnderConstruction = props => {
  return (
    <Alert {...props}>
      <H2>
        <span role="img" aria-label="Construction sign.">
          🚧
        </span>{" "}
        This project case study is under construction!
      </H2>
      <p>
        Feel free to look around, just know that I'll be updating this page
        shortly—see ya soon!
      </p>
    </Alert>
  )
}
