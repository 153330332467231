import React, { useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { H3 } from "./../MDXElements/H3"
import { PlayButton } from "./PlayButton"
import { ResetButton } from "./ResetButton"

const MuteButton = styled(ResetButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.75rem;
  background: ${props => props.theme.colors.black};
  h3 {
    color: ${props => props.theme.colors.white};
  }
`

const VideoWrapper = styled.div`
  position: relative;
  padding-top: ${props => props.paddingTop || 56.25}%;
  background: ${props => props.theme.colors.grayBlue};

  &:hover .vimeo-butt {
    background: ${props => props.theme.colors.red};
  }

  .mute-button {
    display: none;
  }

  &:hover .mute-button {
    display: block;
  }
`

const handleURL = url => {
  if (url.includes("cloudinary")) {
    return getVideoURLs(url)
  } else return url
}

const getVideoURLs = url => {
  const baseUrl = "https://res.cloudinary.com/fartinmartin/video/upload/"
  const fileName = url.split("upload/")[1].split(".")

  return [
    {
      src: `${baseUrl}vc_h265,w_1280,c_limit,q_auto/${fileName[0]}.mp4`,
      type: "video/mp4; codecs=hvc1",
    },
    {
      src: `${baseUrl}vc_vp9,w_1280,c_limit,q_auto/${fileName[0]}.webm`,
      type: "video/webm; codecs=vp9",
    },
    {
      src: `${baseUrl}vc_auto,w_1280,c_limit,q_auto/${fileName[0]}.mp4`,
      type: "video/mp4",
    },
  ]
}

export const Video = props => {
  const [isMuted, setIsMuted] = useState(true)

  return (
    <VideoWrapper {...props}>
      <ReactPlayer
        url={handleURL(props.url)}
        playing={props.playing || false}
        loop={props.loop || false}
        controls={props.controls || false}
        light={props.light || false}
        volume={props.volume || null}
        muted={
          props.url.includes("cloudinary") ? isMuted : props.muted || false
        }
        playbackRate={props.playbackRate || 1}
        progressInterval={props.progressInterval || 1000}
        playsinline={props.playsinline || false}
        pip={props.pip || false}
        config={props.config}
        playIcon={props.light ? <PlayButton /> : null}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      {props.muteButton && (
        <MuteButton
          className="mute-button"
          onClick={() => setIsMuted(!isMuted)}
        >
          <H3>{isMuted ? "unmute" : "mute"}</H3>
        </MuteButton>
      )}
    </VideoWrapper>
  )
}
