import { graphql, useStaticQuery } from "gatsby"

export const useAllProjects = () => {
  const data = useStaticQuery(graphql`
    query RECENT_PUBLISHED_PROJECTS_QUERY {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { fileAbsolutePath: { regex: "/src/projects/" } }
      ) {
        nodes {
          frontmatter {
            title
            kicker
            date(formatString: "YYYY MMMM Do")
            tags
            client
            timeline
            featuredMedia {
              video
              image {
                publicURL
                childImageSharp {
                  sizes(maxWidth: 2000, traceSVG: { color: "#95aaa8" }) {
                    ...GatsbyImageSharpSizes_tracedSVG
                  }
                }
              }
            }
            indexThumbs {
              video
              image {
                publicURL
                childImageSharp {
                  sizes(maxWidth: 2000, traceSVG: { color: "#95aaa8" }) {
                    ...GatsbyImageSharpSizes_tracedSVG
                  }
                }
              }
              secondary {
                publicURL
                childImageSharp {
                  sizes(maxWidth: 2000, traceSVG: { color: "#95aaa8" }) {
                    ...GatsbyImageSharpSizes_tracedSVG
                  }
                }
              }
            }
          }
          fields {
            slug
          }
          id
        }
      }
    }
  `)
  return data
}

export const projectByTitle = (arr, value) => {
  var result = arr.filter(function (o) {
    return o.frontmatter.title === value
  })
  return result ? result[0] : null
}
