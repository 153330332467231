import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { Grid, GridItem } from "../../MDXElements/Grid"
import { Parallax } from "../Parallax"
import { ProjectLink } from "../ProjectLink"
import { omitNull } from "./../../../hooks/useOmitNull"

const Cover = styled(GridItem)`
  z-index: 50;
  pointer-events: none;
  margin-left: -2.5rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    grid-column: 1 / span 3;
    margin-top: 3rem;
    transform: rotate(-5deg);
  }
`

const Spread = styled(GridItem)`
  z-index: 50;
  pointer-events: none;
  margin-left: -2.5rem;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

export const Gage = ({ project }) => {
  const {
    frontmatter: data,
    fields: { slug },
  } = project

  const { indexThumbs } = data
  const result = {
    ...omitNull(indexThumbs[0]),
    ...omitNull(indexThumbs[1]),
    ...omitNull(indexThumbs[2]),
  }

  return (
    <Grid>
      <Cover start={2} span={2} rowStart={1}>
        <Parallax offset={-50}>
          <Img
            sizes={result.secondary[0].childImageSharp.sizes}
            style={{ maxWidth: "80%", margin: "0 auto" }}
          />
        </Parallax>
      </Cover>
      <GridItem start={3} span={4} rowStart={1}>
        <ProjectLink to={slug} data={data} offset={50} />
      </GridItem>
      <Spread start={6} span={3} rowStart={1}>
        <Parallax offset={15}>
          <Img sizes={result.secondary[1].childImageSharp.sizes} />
        </Parallax>
      </Spread>
    </Grid>
  )
}
