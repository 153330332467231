import React from "react"
import styled from "styled-components"

const StyledSection = styled.section`
  padding: 5rem 0;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    padding: 2rem 0;
  }
`

export const Section = props => (
  <StyledSection {...props}>{props.children}</StyledSection>
)
