module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md",".markdown"],"defaultLayouts":{"projects":"/opt/build/repo/src/layouts/Project.js","default":"/opt/build/repo/src/layouts/Page.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#95aaa8","maxWidth":1200,"withWebp":true,"disableBgImageOnAlpha":true,"quality":100,"linkImagesToOriginal":false,"showCaptions":true}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Martin Lindberg's Portfolio","short_name":"Martin Lindberg","start_url":"/","background_color":"#efecea","theme_color":"#de4f2c","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1487cbcd732dbc601de0f61991bce481"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168775168-1","head":false,"anonymize":true,"defer":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
