import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

export const FullWidth = props => (
  <StyledDiv {...props}>{props.children}</StyledDiv>
)
