import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { Grid, GridItem } from "../../MDXElements/Grid"
import { Parallax } from "../Parallax"
import { ProjectLink } from "../ProjectLink"
import { omitNull } from "./../../../hooks/useOmitNull"

const StyledGrid = styled(Grid)`
  margin-top: 25vh !important;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    margin-top: 0 !important;
  }
`

const RightDog = styled(GridItem)`
  pointer-events: none;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 6rem;
    grid-column: 5 / span 4;
  }
`

const LeftDog = styled(GridItem)`
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

export const SwaukCreek = ({ project }) => {
  const {
    frontmatter: data,
    fields: { slug },
  } = project

  const { indexThumbs } = data
  const result = {
    ...omitNull(indexThumbs[0]),
    ...omitNull(indexThumbs[1]),
    ...omitNull(indexThumbs[2]),
  }

  return (
    <StyledGrid>
      <LeftDog start={2} span={2} rowStart={1}>
        <Parallax offset={-60}>
          <Img sizes={result.secondary[0].childImageSharp.sizes} />
        </Parallax>
      </LeftDog>
      <GridItem start={3} span={4} rowStart={1}>
        <ProjectLink to={slug} data={data} offset={100} />
      </GridItem>
      <RightDog start={6} span={2} rowStart={1}>
        <Parallax offset={50}>
          <Img sizes={result.secondary[1].childImageSharp.sizes} />
        </Parallax>
      </RightDog>
    </StyledGrid>
  )
}
