import React from "react"
import styled from "styled-components"

const StyledH2 = styled.h2`
  font-size: 1.25rem;
  font-family: mono45-headline, monospace;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.0625em;
  color: ${props => (props.color ? props.color : null)};
`

export const H2 = props => <StyledH2 {...props}>{props.children}</StyledH2>
