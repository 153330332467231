import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { H3 } from "./../MDXElements/H3"

const SliderWrap = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: ${props => props.paddingTop || 56.25}%;
`

const Mask = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 51;
`

const Source = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
`

const Handle = styled.div`
  user-select: none;
  cursor: col-resize;
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 50%;
    width: 4px;
    left: 50%;
    transform: translateX(-50%);
    background: ${props => props.theme.colors.black};
    opacity: 0.5;
  }

  span {
    z-index: 101;
    padding: 0.5rem 0.75rem;
    background: ${props => props.theme.colors.black};
  }

  h3 {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    span {
      background: ${props => props.theme.colors.white};
    }

    h3 {
      color: ${props => props.theme.colors.black};
    }
  }
`

export const ComparisonSlider = props => {
  const [isDragging, setIsDragging] = useState(false)

  const wrap = useRef(null)
  const handle = useRef(null)
  const sourceTwo = useRef(null)
  const mask = useRef(null)

  useEffect(() => {
    sourceTwo.current.style.width = `${wrap.current.offsetWidth}px`
  }, [sourceTwo, wrap])

  const handleMouseDown = () => {
    setIsDragging(true)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const handleMouseMove = e => {
    if (!isDragging) return
    sourceTwo.current.style.width = `${wrap.current.offsetWidth}px`

    const mouseX = e.pageX
    const containerX = wrap.current.offsetLeft
    const containerWidth = wrap.current.offsetWidth
    const handleWidth = handle.current.offsetWidth
    const relativeX = mouseX - containerX
    const margin = 20 + handleWidth
    const min = margin
    const max = containerWidth - margin

    let leftPos

    if (relativeX < min) {
      leftPos = `${(min / containerWidth) * 100}%`
    } else if (relativeX > max) {
      leftPos = `${(max / containerWidth) * 100}%`
    } else {
      leftPos = `${(relativeX / containerWidth) * 100}%`
    }

    mask.current.style.width = leftPos
    handle.current.style.left = leftPos
  }

  return (
    <SliderWrap
      {...props}
      ref={wrap}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleMouseMove}
      onTouchCancel={handleMouseLeave}
    >
      <Handle
        ref={handle}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        style={{ left: props.position || "50%" }}
      >
        <span style={{ transform: isDragging && `scale(1.1)` }}>
          <H3>Drag me!</H3>
        </span>
      </Handle>

      <Mask ref={mask} style={{ width: props.position || "50%" }}>
        <Source ref={sourceTwo}>{props.children[1]}</Source>
      </Mask>

      <Source>{props.children[0]}</Source>
    </SliderWrap>
  )
}
