import Img from "gatsby-image"
import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { Video } from "../Global/Video"
import { omitNull } from "./../../hooks/useOmitNull"
import Link from "./../Global/Link"
import { Parallax } from "./Parallax"
import { ProjectMeta } from "./ProjectMeta"

export const ProjectLink = ({
  data,
  to,
  offset = 0,
  paddingTop = 56.25,
  loop = true,
}) => {
  const { featuredMedia, indexThumbs } = data

  const featuredMediaResult = {
    ...omitNull(featuredMedia[0]),
    ...omitNull(featuredMedia[1]),
  }

  const indexThumbsResult = {
    ...omitNull(indexThumbs[0]),
    ...omitNull(indexThumbs[1]),
    ...omitNull(indexThumbs[2]),
  }

  const [isHovered, setHovered] = useState(false)

  const [ref, inView] = useInView({
    rootMargin: "-40% 0px -40% 0px",
  })

  return (
    <Parallax offset={offset}>
      <div ref={ref}>
        <Link
          to={to}
          style={{ textDecoration: "none" }}
          onMouseEnter={() => setHovered(state => !state)}
          onMouseLeave={() => setHovered(state => !state)}
          onTouchStart={() => setHovered(state => !state)}
          onTouchEnd={() => setHovered(state => !state)}
        >
          {indexThumbsResult.video || featuredMediaResult.video ? (
            <Video
              url={indexThumbsResult.video || featuredMediaResult.video}
              paddingTop={paddingTop}
              controls={false}
              playing={true}
              muted={true}
              loop={loop}
              playsinline={true}
            />
          ) : (
            <Img
              sizes={
                indexThumbsResult.image.childImageSharp.sizes ||
                featuredMediaResult.image.childImageSharp.sizes
              }
            />
          )}
          <ProjectMeta
            data={data}
            isHovered={inView || (!inView && isHovered)}
          />
        </Link>
      </div>
    </Parallax>
  )
}

// this could be condensed.. 🤔
