import React, { useContext } from "react"
import { animated, useSpring } from "react-spring"
import { ScrollContext } from "./../Global/ScrollContext"

export const Parallax = ({ offset, children }) => {
  const { scrollY } = useContext(ScrollContext)

  const [{ transformY }, setTransformY] = useSpring(() => ({
    transformY: 0,
  }))

  const parallaxLevel = offset
  setTransformY({ transformY: scrollY })
  const parallaxTransform = transformY.interpolate(
    o => `translateY(${o / parallaxLevel}px)`
  )

  return (
    <animated.div style={{ transform: parallaxTransform }}>
      {children}
    </animated.div>
  )
}
