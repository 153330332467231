import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"

const LinkStyle = styled.a`
  text-decoration: none;
`

const Link = ({ children, to, ...props }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink to={to} {...props}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <LinkStyle href={to} {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </LinkStyle>
  )
}

export default Link
