import React from "react"
import styled from "styled-components"

const StyledUL = styled.ul`
  list-style: none;
  > li:not(:first-child) {
    margin-top: 0.5rem;
  }
`

export const UL = props => <StyledUL {...props}>{props.children}</StyledUL>
