import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Link from "./../Global/Link"
import { Logo } from "./../Global/Logo"
import { Video } from "./../Global/Video"
import { ProjectMeta } from "./../Project/ProjectMeta"
import { ComparisonSlider } from "./ComparisonSlider"
import { FullWidth } from "./FullWidth"
import { Grid, GridItem } from "./Grid"
import { H1 } from "./H1"
import { H2 } from "./H2"
import { H3 } from "./H3"
import { Section } from "./Section"
import { TextBlock } from "./TextBlock"
import { UL } from "./UL"
import { UnderConstruction } from "./UnderConstruction"

export const MDXComponents = {
  h1: props => <H1 {...props} />,
  h2: props => <H2 {...props} />,
  h3: props => <H3 {...props} />,
  ul: props => <UL {...props} />,
  Link,
  Logo,
  Video,
  FullWidth,
  TextBlock,
  Section,
  Grid,
  GridItem,
  ProjectMeta,
  UnderConstruction,
  SRLWrapper,
  ComparisonSlider,
}
