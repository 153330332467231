import React from "react"
import styled from "styled-components"

const StyledResetButton = styled.button`
  border: none;

  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  text-align: inherit;
  outline: none;
  border-radius: 0;
  cursor: pointer;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export const ResetButton = props => (
  <StyledResetButton {...props}>{props.children}</StyledResetButton>
)
