import React from "react"
import styled from "styled-components"
import { Grid, GridItem } from "../../MDXElements/Grid"
import { Parallax } from "../Parallax"
import { ProjectLink } from "../ProjectLink"
import draw from "./../../../projects/exquisite-corpse-club/images/thumbs/draw.svg"

const Draw = styled(GridItem)`
  z-index: 100;
  pointer-events: none;
  transform: translateY(40%);
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

export const ECC = ({ project }) => {
  const {
    frontmatter: data,
    fields: { slug },
  } = project

  return (
    <Grid style={{ transform: "translateY(-28%)" }}>
      <GridItem start={2} span={3} rowStart={1} rowSpan={2}>
        <ProjectLink to={slug} data={data} offset={50} paddingTop={100} />
      </GridItem>
      <Draw start={4} span={2} rowStart={2}>
        <Parallax offset={-10}>
          <img src={draw} alt="draw" />
        </Parallax>
      </Draw>
    </Grid>
  )
}
