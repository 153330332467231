import React from "react"
import styled from "styled-components"

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 3rem calc(var(--line-height) * 1rem);
  grid-template-columns: repeat(8, minmax(0, 1fr));

  img {
    max-width: 100%;
  }
`

export const Grid = props => (
  <StyledGrid {...props}>{props.children}</StyledGrid>
)

const StyledGridItem = styled.div`
  grid-column: ${props => props.start || 1} / span ${props => props.span || 4};
  grid-row: ${props =>
    props.rowStart || props.rowStart
      ? `${props.rowStart} / span ${props.rowSpan}`
      : `unset`};

  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    grid-column: 1 / -1;
  }
`
export const GridItem = props => {
  return (
    <StyledGridItem className="grid-item" {...props}>
      {props.children}
    </StyledGridItem>
  )
}
