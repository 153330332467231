import React from "react"
import { animated, useSpring, useTrail } from "react-spring"
import styled from "styled-components"
import { H2 } from "../MDXElements/H2"

const ProjectMetaWrap = styled.div`
  padding: 1rem;
  color: ${props => props.theme.colors.black};
`

const ProjectTitle = styled.div`
  overflow: hidden;
  display: block;
`

const ProjectTag = styled.li`
  overflow: hidden;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.25em;
  }

  font-family: "Panama";
  font-weight: 400;
  font-style: italic;
`

export const ProjectMeta = ({ data, isHovered }) => {
  const { title, tags } = data

  const config = { mass: 5, tension: 3000, friction: 300 }

  const revealText = useSpring({
    opacity: isHovered ? 1 : 0,
    transform: isHovered ? "translateY(0)" : "translateY(150%)",
    from: {
      opacity: 0,
      transform: "translateY(150%)",
    },
  })

  const trail = useTrail(tags.length, {
    config,
    opacity: isHovered ? 1 : 0,
    transform: isHovered ? "translateY(0)" : "translateY(150%)",
    delay: 200,
    from: { opacity: 0, transform: "translateY(150%)" },
  })

  return (
    <ProjectMetaWrap>
      <ProjectTitle>
        <animated.div style={revealText}>
          <H2>{title}</H2>
        </animated.div>
      </ProjectTitle>
      <ul style={{ display: "flex" }}>
        {trail.map(({ transform, ...rest }, index) => (
          <ProjectTag key={tags[index]}>
            <animated.div
              style={{
                ...rest,
                transform,
              }}
            >
              {tags[index]}
              {index < trail.length - 1 ? "," : null}
            </animated.div>
          </ProjectTag>
        ))}
      </ul>
    </ProjectMetaWrap>
  )
}
