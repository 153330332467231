import React from "react"

export const Logo = props => {
  function changeFill(e) {
    const colors = [
      // "#efecea", // white
      "#de4f2c",
      "#95aaa8",
      "#5b73b0",
      "#67a843",
      "#d47f94",
      // "#f4cf10", // yellow
    ]

    var color = colors[Math.floor(Math.random() * colors.length)]
    e.target.style.fill = color
  }
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 656 353.11"
        {...props}
      >
        <g id="logo-color" data-name="Martin Lindberg">
          <polygon
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#de4f2c" }}
            points="9.94 176.52 29.48 177.23 49.48 83.86 54.25 165.39 86.38 163.79 103.27 101.1 105.28 160.58 162.88 158.43 167.21 119.05 123.95 126.96 126.87 50.1 100.95 53.24 74.1 104.62 64.01 54.5 40.36 57.31 29.51 87.3 22.13 53.65 10.24 56.32 9.94 176.52"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#95aaa8" }}
            d="M269.63,125.71s-5.65,21.34-5.47,21.82-6.88-.25-6.88-.25.71-73.11.57-77.36C257,45.69,228.44,34.31,210,33.25c-21.88-1.27-62.66,19.1-62.66,19.1l-1.5,49.13,22.93-4.34.93-32.49c73.13-31.46,61,18.28,61.94,28-57.44-19.07-56.42,18.4-53.87,41.87,2,19,20.46,23.25,20.46,23.25s33-7,36-9.47l21.78,16.49s12.5-1.72,17.78-3.19c5.43-6.48,18.91-36.74,18.91-36.74ZM196,137.5l-2.45-29,37.75-.72.31,24.61Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#de4f2c" }}
            d="M239.12,288.88s-2.71,31-2.46,31.65-9.69-.34-9.69-.34l4.32-151.87-22,4.63-7.24,96.23c-47.06,2.5-93.2,12.9-90.9,46.13,2.57,36.92,37.86,32.47,37.86,32.47l57.88-22.28c3.12,6.94,18.19,19.42,18.19,19.42s12-2.23,19.43-4.3c7.66-9.14,20.69-53.95,20.69-53.95Zm-40,18.23-63.41,19.61-2.15-31L201,282.81Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#5b73b0" }}
            d="M429.14,275.4l-81.13-7c19.86-6.62,27.73-19.06,25.15-42.77C369.93,195.87,341,189.13,341,189.13s-38.21,19.13-42.89,23.09c-1.62-10.09-17.49-21.73-17.49-21.73S267.26,193,259,195.28C245.8,205.72,241.06,255,241.06,255l23.92-2s3-34.51,2.73-35.26,10.8.38,10.8.38-4,123.34-2.79,129.89l21.73,5.17,1.44-18.58,103.4,15.19C457,337.28,429.14,275.4,429.14,275.4Zm-77.91-59.15,4.39,35.81-53.18,8.11-.32-26Zm-50.62,95.82,3.6-26.47,100.64.78,4.39,35.82Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#67a843" }}
            d="M357.26,5.55,286.59,21.14l-13.2,84.18,22.18-.26,7.67-48.32L320.19,176l47.62-19.72-4-29.54L336.19,143,323.65,58.61,371,61.43C381.75,58.74,399.67-1.65,357.26,5.55ZM356.44,45l-52.26-8.26.77-9.54,55-7.1Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#de4f2c" }}
            d="M638,300.72l-99.28,17.59-27.89-78.2c62.94-5.45,56.51-65-26.58-62.16L451.42,346.62l26.22-.55,15.45-110.28L518.16,343l124.74-2.65ZM497.14,195.3l37.43.7-6.31,28-33.6-13.76Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#5b73b0" }}
            d="M393.62,151.07c-.76-19.82,1.79-48.16,1.79-48.16l-45.55,5.51-2.77-27.65,52.51-2.56,2.74-56.3,29.19-1-8.89,56.94,27.57-3.1-.75,28.82-31.92-1.42-2.48,28.59,147.22-.9,4.63,31.54Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#d47f94" }}
            d="M459.05,18.06c-10-17,35.61-23.88,48.73-12.37C517.6,14.3,468,33.19,459.05,18.06Z"
          />
          <polygon
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#d47f94" }}
            points="510.82 111.98 499.14 40.23 471.2 43.42 472.87 111.98 510.82 111.98"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#67a843" }}
            d="M52.63,206.91c-8.32-7.28,7.18-16.44,22.3-20.35,9.23-2.38,18.32-2.81,21.75.35C105.34,194.89,66,218.63,52.63,206.91ZM81.78,272.7l-1.07-52.42-26.12,1.43s-1.33,56.93-1.67,58.18Z"
          />
          <polygon
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#de4f2c" }}
            points="533.58 111.93 559.66 111.68 559.64 81.2 612.06 163.41 632.65 162.71 628.1 41.11 606.67 41.84 610.65 98.53 560.15 32.15 528.2 33.24 533.58 111.93"
          />
          <polygon
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#5b73b0" }}
            points="7.75 200.57 0 347.29 101.2 347.39 99 300.24 26.86 315.09 33.28 198.87 7.75 200.57"
          />
          <polygon
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#d47f94" }}
            points="112.61 185.36 106.65 266.39 128.06 265.66 130.42 224.18 153.74 211.23 157.26 256.36 185.32 254.96 165.95 186.84 145.29 189.31 128.96 203.16 128.66 184.82 112.61 185.36"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#95aaa8" }}
            d="M440.33,224.62l-.55-16.21-28.86,4.29.29-12.56,46.31-4,2.41-20.84-49.62,7-.85-10-22.47,2,2.71,13.83s3.13,64.08,3.92,68.25c0,0,20,0,34.07.8l23.9,2L455,237.4l-9.22.66h0l-34.08-.46-.58-10.27Z"
          />
          <path
            onMouseOver={changeFill}
            onFocus={() => void 0}
            style={{ fill: "#67a843" }}
            d="M656,174.08c-21.62-2.3-31.66,6-33.06,7.47-7.66-1.64-35.25-.24-41.07,4.09s-9.11,10-10.09,13.37c-2.1,6.17-.48,14.18,9.26,17.69a134.38,134.38,0,0,0-13.85,4.1l-2.8,3.41s1.41,3.87,3.93,5.37,17.61,7,17.61,7l-43,11.67c-6.82,22.61,4.71,45.06,9.61,47.38,0,0,15.83-2.84,41.44-7.21,32.59-5.57,50.63-10,50.63-10s5-26-3.08-40l-46.21-2,.41-12.93s23.95,6.56,41.21-2.54c9.64-6.08,9.72-9.9,9.72-9.9l-3.79-20.62,6.89,1.15Zm-31,76.34,5,18.2-70,8.92-.11-19.47Zm-35.47-40.9,1.65-18L627.5,193l2.33,17.58Z"
          />
        </g>
      </svg>
    </>
  )
}
