import React from "react"
import styled from "styled-components"
import { projectByTitle, useAllProjects } from "../../hooks/useAllProjects"
import { ECC } from "./Projects/ExquisiteCorpseClub"
import { Gage } from "./Projects/Gage"
import { Lacanche } from "./Projects/Lacanche"
import { Portshowlio2020 } from "./Projects/Portshowlio2020"
import { SwaukCreek } from "./Projects/SwaukCreek"
import { Vera } from "./Projects/Vera"
import { Vitch } from "./Projects/Vitch"

const Wrapper = styled.div`
  margin-bottom: 33vh;
  z-index: 500;
  position: relative;

  > [class^="Grid"]:not(:first-child) {
    margin-top: 10vh;

    @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
      margin-top: 0;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    > [class^="Grid"]:nth-child(2) {
      margin-top: -12vh;
    }
  }
`

export const ProjectIndex = () => {
  const {
    allMdx: { nodes: projects },
  } = useAllProjects()

  return (
    <Wrapper>
      <ECC project={projectByTitle(projects, "exquisite corpse club")} />
      <Portshowlio2020 project={projectByTitle(projects, "Portshowlio 2020")} />
      <Vitch project={projectByTitle(projects, "Vitch")} />
      <Vera project={projectByTitle(projects, "The Vera Project")} />
      <Gage project={projectByTitle(projects, "Gage")} />
      <Lacanche project={projectByTitle(projects, "Lacanche")} />
      <SwaukCreek project={projectByTitle(projects, "Swauk Creek")} />
      {/* <OtherPeople project={projectByTitle(projects, "Other People")} /> */}
      {/* <SCCADocs project={projectByTitle(projects, "SCCA Docs")} /> */}
    </Wrapper>
  )
}
