import React from "react"
import styled from "styled-components"
import { Grid, GridItem } from "../../MDXElements/Grid"
import { ProjectLink } from "../ProjectLink"

const StyledGrid = styled(Grid)`
  margin-top: 18vh !important;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    margin-top: 0 !important;
  }
`

export const Lacanche = ({ project }) => {
  const {
    frontmatter: data,
    fields: { slug },
  } = project

  return (
    <StyledGrid>
      <GridItem start={2} span={5} rowStart={1}>
        <ProjectLink to={slug} data={data} offset={50} />
      </GridItem>
    </StyledGrid>
  )
}
