import React from "react"
import styled, { keyframes } from "styled-components"
import glyphs from "./glyphs.svg"

const tickerScroll = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-500px, 0, 0); /* The image width */
  }
`

const TickerWrap = styled.div`
  position: relative;
  overflow: hidden;

  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  height: 11rem;
  background: #000000;
`

const GlyphDiv = styled.div`
  height: 1.28541875rem;
  width: 100%;
  position: absolute;
  top: 4.85rem;
  width: 2000px; /* The image width times 4 */
  animation: ${tickerScroll} 50s linear infinite;
  z-index: 0;
`

export const Ticker = () => {
  return (
    <TickerWrap>
      <GlyphDiv style={{ background: `url("${glyphs}") repeat-x` }} />
    </TickerWrap>
  )
}
