import { MDXProvider } from "@mdx-js/react"
import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"
import { ThemeProvider } from "styled-components"
import { MDXComponents } from "./src/components/MDXElements"
import "./src/styles/fonts.css"
import theme from "./src/styles/theme"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <SimpleReactLightbox>
      <MDXProvider components={MDXComponents}>{element}</MDXProvider>
    </SimpleReactLightbox>
  </ThemeProvider>
)
