import React from "react"
import styled from "styled-components"

const SVG = styled.svg`
  cursor: pointer;

  color: #fff;
  font-size: 10px;
  width: 2em;
  height: 2em;

  margin: 0 auto;
  margin-right: -0.2em;
  display: block;
`

const VimeoButt = styled.div`
  cursor: pointer;

  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(23, 35, 34, 0.75);
  border-radius: 0.5em;

  width: 6.5em;
  height: 4em;

  color: #fff;

  transition: opacity 250ms ease-out, background-color 40ms, color 40ms;
  margin: 0;

  z-index: 14;

  &:hover {
    background: ${props => props.theme.colors.red};
  }
`

export const PlayButton = props => {
  return (
    <VimeoButt {...props} className="vimeo-butt">
      <div>
        <SVG
          viewBox="0 0 20 20"
          preserveAspectRatio="xMidYMid"
          focusable="false"
          aria-labelledby="play-icon-title"
          role="img"
          fill="currentColor"
        >
          <title id="play-icon-title">Play</title>
          <polygon points="1,0 20,10 1,20"></polygon>
        </SVG>
      </div>
    </VimeoButt>
  )
}
