import React from "react"
import styled from "styled-components"

const StyledH1 = styled.h1`
  font-size: 3rem;
  font-family: gopher, sans-serif;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0.0625em;
  line-height: 1;
`

export const H1 = props => <StyledH1 {...props}>{props.children}</StyledH1>
