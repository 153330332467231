export default {
  fonts: { body: ["sans-serif", "Roboto"] },
  colors: {
    black: "#282829",
    black50: "#868687",
    black75: "#585859",
    black85: "#464647",
    white: "#efecea",
    red: "#de4f2c",
    grayBlue: "#95aaa8",
    blue: "#5b73b0",
    green: "#67a843",
    pink: "#d47f94",
    yellow: "#f4cf10",
    lightYellow: "#fffadd",
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
}
