import React from "react"
import styled from "styled-components"

const StyledH3 = styled.h3`
  font-size: 1rem;
  /* margin-top: 0.25rem; */
  font-family: mono45-headline, monospace;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.0625em;
  color: ${props => props.theme.colors.black75};
`

export const H3 = props => <StyledH3 {...props}>{props.children}</StyledH3>
