import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { Grid, GridItem } from "../../MDXElements/Grid"
import { Parallax } from "../Parallax"
import { ProjectLink } from "../ProjectLink"
import { omitNull } from "./../../../hooks/useOmitNull"

const Sticker = styled(GridItem)`
  margin-top: 20rem;
  z-index: 50;
  pointer-events: none;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

export const Vera = ({ project }) => {
  const {
    frontmatter: data,
    fields: { slug },
  } = project

  const { indexThumbs } = data
  const result = {
    ...omitNull(indexThumbs[0]),
    ...omitNull(indexThumbs[1]),
    ...omitNull(indexThumbs[2]),
  }

  return (
    <Grid>
      <GridItem start={4} span={4} rowStart={1}>
        <ProjectLink to={slug} data={data} offset={50} />
      </GridItem>
      <Sticker start={6} span={3} rowStart={1}>
        <Parallax offset={-25}>
          <Img
            sizes={result.secondary[0].childImageSharp.sizes}
            style={{ maxWidth: "80%", margin: "0 auto" }}
          />
        </Parallax>
      </Sticker>
    </Grid>
  )
}
