import React from "react"
import styled from "styled-components"
import Link from "../Global/Link"
import { H2 } from "../MDXElements/H2"

const SubHead = styled(H2)`
  font-size: 1rem;
  color: ${props => props.theme.colors.black75};
  &:not(:last-child) {
    margin-right: 0.5em;
  }
`

const Item = styled.span`
  font-family: "Panama";
  font-weight: 400;
  font-style: italic;
  &:not(:last-child) {
    margin-right: 0.25em;
  }
`

const MetaListItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const StyledDiv = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    > div:last-child {
      margin-top: 0.5em;
    }
  }
`

export const ProjectMeta = props => {
  const { client, timeline } = props.frontmatter

  let clientName, clientLink
  if (client.startsWith("(") && client.endsWith("]")) {
    clientName = client.split("(")[1].split(")")[0]
    clientLink = client.split("[")[1].split("]")[0]
  }

  return (
    <StyledDiv {...props}>
      <MetaList
        heading="Client"
        items={clientName || client}
        link={clientLink}
        style={{ gridColumn: "span 1" }}
      />
      <MetaList
        heading="Timeline"
        items={timeline}
        style={{ gridColumn: "span 1" }}
      />
    </StyledDiv>
  )
}

const MetaList = props => {
  const { heading, items, link } = props
  return (
    <MetaListItem {...props}>
      <SubHead {...props}>{heading && heading}</SubHead>
      <div>
        {typeof items === "string" ? (
          <Item>
            {link && <Link to={link}>{items}</Link>}
            {!link && items}
          </Item>
        ) : (
          items.map((item, index) => (
            <Item key={index}>
              <span>
                {item}
                {index < items.length - 1 ? ", " : null}
              </span>
            </Item>
          ))
        )}
      </div>
    </MetaListItem>
  )
}
