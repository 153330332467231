import React from "react"
import styled from "styled-components"
import { H2 } from "./H2"

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextBlock = props => (
  <Flex {...props}>
    <H2>{props.heading}</H2>
    <div>{props.children}</div>
  </Flex>
)
